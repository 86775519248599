import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { useStore } from '../../contexts/user';
import { useStore as authStore } from '../../contexts/auth';
import useAuth from './useAuth';

const useApp = () => {
	const router = useRouter();

	const { authenticated } = useAuth();
	const { data: { userData } } = authStore();
	const { data: userStoreData, dispatch: userDispatch } = useStore();

	const [_isMember, _setIsMember] = useState(false);
	const isMember = useRef(false);

	const setIsMember = (s) => {
		_setIsMember(s); // used as default
		isMember.current = s; // used in events
	};

	const showCallout = (type = 'PAID_ACCESS') => userDispatch({ type: `SET_${type}_CALLOUT`, payload: { type } });

	// Check, if user is authenticated & has pro account - then continue - otherwise show callout with additional data
	const paidAccess = (next) => {
		if (isValidUserLevel('4000')) {
			if (next) {
				if (typeof next === 'string' && next.indexOf('/') !== -1) {
					router.push(next);
				} else if (typeof next === 'function') {
					next();
				}
			}

			return true;
		}

		showCallout();
		return false;
	};

	const dtmStringToDateObj = (dtm) => {
		const dateTimeParts = dtm.split(' ');
		const timeParts = dateTimeParts[1].split(':');
		const dateParts = dateTimeParts[0].split('-');

		return new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]);
	};

	const expireDtmFormated = () => {
		if (userData.ulevel_expire_dtm) {
			const dateTimeParts = userData.ulevel_expire_dtm.split(' ');
			const timeParts = dateTimeParts[1].split(':');
			const dateParts = dateTimeParts[0].split('-');

			return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`;
		}

		return null;
	};

	const dtmCountdown = (dtm) => {
		if (dtm) {
			// Find the distance between now and the count down date
			const distance = dtm.getTime() - new Date().getTime(); // dtm = new Date("Aug 4, 2021 15:20:25")

			// Time calculations for days, hours, minutes and seconds
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			// const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			return {
				dtmString: `${days}d ${hours}h ${minutes}m`,
				expired: distance < 0
			};
		}

		return {};
	};

	const isValidUserLevel = (ulevel) => {
		if (ulevel && authenticated && userData && Object.keys(userData).length > 0 && userData.ulevel === parseInt(ulevel, 10) && userData.ulevel_expire_dtm) {
			// ulevel_expire_dtm ex.: 2020-12-21 15:58:11
			return new Date().getTime() < dtmStringToDateObj(userData.ulevel_expire_dtm).getTime();
		}

		return false;
	};

	let timer = null;

	useEffect(() => {
		if (authenticated && userData.ulevel) {
			// Initial state, when ulevel changed (when user data is loaded via API for ex.)
			setIsMember(isValidUserLevel('4000'));

			// Start interval, which check every minute, if user PRO is still active
			timer = setInterval(() => {
				if (isMember.current === true && isValidUserLevel('4000') === false) {
					setTimeout(() => window.location.reload(), 4000); // first show HeaderNotification, then after few seconds reload page
				}
			}, 60000);

			return () => {
				if (typeof timer !== 'undefined') clearTimeout(timer);
			};
		} else if (_isMember === true) {
			setIsMember(false);
		}

		return null;
	}, [userData.ulevel, userData.ulevel_expire_dtm, authenticated]);

	// == User level notification handle

	const getHeaderNotification = () => {
		if (userData && Object.keys(userData).length > 0) {
			const countdown = dtmCountdown(dtmStringToDateObj(userData.ulevel_expire_dtm));

			// Find type depend on product_codeid
			let type = null;
			let color = null;

			switch (userData.product_codeid) {
			case '0101006': // Preizkusno obdobje
				type = countdown.expired ? 'trial-expired' : 'trial-countdown';
				color = countdown.expired ? 'graylight' : 'green';
				break;

			default: // paid products
				if (countdown.expired) {
					type = 'pro-expired';
					color = 'graylight';
				}
				break;
			}

			return {
				type,
				countdown: countdown.dtmString || null,
				expired: countdown.expired || null,
				color
			};
		}

		return {};
	};

	return { authenticated, userData, userStoreData, userDispatch, isMember: _isMember, showCallout, paidAccess, checkAccess: isValidUserLevel, getHeaderNotification, expireDtmFormated };
};

export default useApp;
